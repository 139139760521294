import * as React from 'react'

import { PageType } from '@thg-commerce/enterprise-core/src/App/types'
import { StripBanner, USPBar } from '@thg-commerce/gravity-patterns'

// import { isMobileDevice } from '@thg-commerce/gravity-system'
// import { mq } from '@thg-commerce/gravity-theme'
// import Media from 'react-media'
import { useSiteConfig } from '../ConfigurationLoader'

export interface WidgetBannerProps {
  userAgent: string
  showStripBanner: boolean
  widgetData?: any
  pageType?: PageType
}

export const WidgetBanner = React.memo((props: WidgetBannerProps) => {
  const { /*userAgent,*/ showStripBanner } = props

  const { hasZipPayUsp, hasStoreUsp, hasClickAndCollectUsp } = useSiteConfig()
  const widgets = props.widgetData?.header?.widgets
    .reduce((accumulator, widget) => {
      switch (widget.__typename) {
        case 'ResponsiveUSPBar':
          accumulator.push(
            <USPBar
              key="USPBar"
              isDesktop={true}
              uspBarData={widget}
              userAgent={props.userAgent}
              useAlternateIcon={{
                qualityText: hasZipPayUsp,
                clickAndCollectText: hasClickAndCollectUsp,
                storeText: hasStoreUsp,
              }}
            />,
          )
          break
        case 'ResponsiveUSPBarCustomisable':
          accumulator.push(
            <USPBar
              key="USPBarCustomisable"
              isDesktop={true}
              uspBarData={widget}
              userAgent={props.userAgent}
              useAlternateIcon={{
                qualityText: hasZipPayUsp,
                clickAndCollectText: hasClickAndCollectUsp,
                storeText: hasStoreUsp,
              }}
            />,
          )
          break
        case 'GlobalStripBanner':
          {
            if (showStripBanner) {
              const getSpecificStripBannerText = (type?: PageType) => {
                switch (type) {
                  case PageType.HOME_PAGE:
                    return {
                      text: widget.stripBannerTextHomepage,
                      href: widget.stripBannerURLHomepage,
                    }
                  case PageType.LANDING:
                    return {
                      text: widget.stripBannerTextPLP,
                      href: widget.stripBannerURLPLP,
                    }
                  case PageType.SEARCH:
                    return {
                      text: widget.stripBannerTextPLP,
                      href: widget.stripBannerURLPLP,
                    }
                  case PageType.ACCOUNT:
                    return {
                      text: widget.stripBannerTextAccount,
                      href: widget.stripBannerURLAccount,
                    }
                  case PageType.BASKET:
                    return {
                      text: widget.stripBannerTextBasket,
                      href: widget.stripBannerURLBasket,
                    }
                  case PageType.PRODUCT:
                    return {
                      text: widget.stripBannerTextPDP,
                      href: widget.stripBannerURLPDP,
                    }
                  default:
                    return {
                      text: widget.stripBannerText,
                      href: widget.stripBannerURL,
                    }
                }
              }
              const { text, href } = getSpecificStripBannerText(props.pageType)

              const hideDefaultBanner =
                widget.hideDefaultBanner === 'true' && text === ''

              accumulator.push(
                <StripBanner
                  key="Stripbanner"
                  stripBannerText={
                    hideDefaultBanner
                      ? undefined
                      : text || widget.stripBannerText
                  }
                  stripBannerURL={href || widget.stripBannerURL}
                  hideDefaultBanner={hideDefaultBanner}
                />,
              )
            }
          }
          break
        default:
          break
      }
      return accumulator
    }, [])
    .sort((a) => {
      return a.key === 'USPBar' ? -1 : 1
    })

  return <aside data-testid="widget-banner">{widgets}</aside>
})
